div.code-block {
  background: #f0f0f0;
  padding: 0.75rem;
  margin-bottom: 2rem;
}

code.inline-slack {
  background-color: #f0f0f0;
  border-radius: 5px;
  color: #e01563;
  padding: 4px;
}

img.airtable {
  max-width: 100%;
  margin-bottom: 2rem;
  max-height: 600px;
}
